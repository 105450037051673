import { ConfigProvider } from '@portals/sip-client-data/src/general/Config';
import GoogleTagManager from '@sfp/react-gtm-module';
import getConfig from 'next/config';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

// eslint-disable-next-line @if6/fi-no-absolute-urls
const DOMAIN = 'www.googletagmanager.com';

const {
  publicRuntimeConfig: {
    NEXT_PUBLIC_GTM_ID = ConfigProvider.getConfig().get('NEXT_PUBLIC_GTM_ID'),
    NEXT_PUBLIC_GTM_AUTH = ConfigProvider.getConfig().get('NEXT_PUBLIC_GTM_AUTH'),
    NEXT_PUBLIC_GTM_ENV = ConfigProvider.getConfig().get('NEXT_PUBLIC_GTM_ENV'),
  },
} = getConfig();

export const TagManager = (): React.ReactElement => {
  const { asPath } = useRouter();

  useEffect(() => {
    GoogleTagManager.initialize({
      gtmId: NEXT_PUBLIC_GTM_ID,
      auth: NEXT_PUBLIC_GTM_AUTH,
      preview: NEXT_PUBLIC_GTM_ENV,
      domain: DOMAIN,
    });
  }, []);

  useEffect(() => {
    GoogleTagManager.dataLayer({ dataLayer: { event: 'pageview' } });
  }, [asPath]);

  return (
    <>
      <Head>
        <link key="gtm-perconnect" rel="preconnect" href={`https://${DOMAIN}`} crossOrigin="use-credentials" />
      </Head>
      <noscript>
        <iframe
          src={`https://${DOMAIN}/ns.html?id=${NEXT_PUBLIC_GTM_ID}&gtm_auth=${NEXT_PUBLIC_GTM_AUTH}&gtm_preview=${NEXT_PUBLIC_GTM_ENV}&gtm_cookies_win=x`}
          height="0"
          width="0"
          title="Google Tag Manager"
          style={{
            display: 'none',
            visibility: 'hidden',
          }}
        ></iframe>
      </noscript>
    </>
  );
};

TagManager.displayName = 'TagManager';
