import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const Delete: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <path d="M16.3125 15.1875C16.623 15.1875 16.875 15.4395 16.875 15.75V22.5C16.875 22.8105 16.623 23.0625 16.3125 23.0625C16.002 23.0625 15.75 22.8105 15.75 22.5V15.75C15.75 15.4395 16.002 15.1875 16.3125 15.1875Z" />
    <path d="M19.6875 15.1875C19.998 15.1875 20.25 15.4395 20.25 15.75V22.5C20.25 22.8105 19.998 23.0625 19.6875 23.0625C19.377 23.0625 19.125 22.8105 19.125 22.5V15.75C19.125 15.4395 19.377 15.1875 19.6875 15.1875Z" />
    <path d="M20.8125 12.375V11.8125C20.8125 10.8804 20.0571 10.125 19.125 10.125H16.875C15.9429 10.125 15.1875 10.8804 15.1875 11.8125V12.375H10.6875C10.377 12.375 10.125 12.627 10.125 12.9375C10.125 13.248 10.377 13.5 10.6875 13.5H25.3125C25.623 13.5 25.875 13.248 25.875 12.9375C25.875 12.627 25.623 12.375 25.3125 12.375H20.8125ZM16.3125 12.375V11.8125C16.3125 11.502 16.5645 11.25 16.875 11.25H19.125C19.4355 11.25 19.6875 11.502 19.6875 11.8125V12.375H16.3125Z" />
    <path d="M22.5287 25.875H13.4713C13.4707 25.875 13.4702 25.875 13.4696 25.875C12.8694 25.875 12.3789 25.4048 12.3463 24.8124V24.8096L11.8125 14.0625H12.9375L13.4719 24.75H22.5281L23.0625 14.0625H24.1875L23.6531 24.8063C23.6222 25.4031 23.1311 25.875 22.5298 25.875C22.5292 25.875 22.5293 25.875 22.5287 25.875V25.875Z" />
  </SvgIcon>
);
