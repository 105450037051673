export const apiFrontendMapping = {
  zipCityEstateId: 'zip_city_estate_id',
  perimeter: 'perimeter',
  limit: 'limit',
  page: 'page',
  sortBy: 'sort_by',
  returnData: 'return_data',
  objectType: 'object_type',
  marketingType: 'marketing_type',
  usageType: 'usage_type',
  'estateIds[]': 'estate_ids[]',
  estateIds: 'estate_ids',
  minPrice: 'min_price',
  maxPrice: 'max_price',
  minRent: 'min_rent',
  maxRent: 'max_rent',
  minMarketValue: 'min_market_value',
  maxMarketValue: 'max_market_value',
  minLease: 'min_lease',
  maxLease: 'max_lease',
  minLivingSpace: 'min_living_space',
  maxLivingSpace: 'max_living_space',
  minPropertySize: 'min_property_size',
  maxPropertySize: 'max_property_size',
  minTotalSpace: 'min_total_space',
  maxTotalSpace: 'max_total_space',
  minNumberRooms: 'min_number_rooms',
  maxNumberRooms: 'max_number_rooms',
  'flatTypes[]': 'flat_types[]',
  'houseTypes[]': 'house_types[]',
  'propertyTypes[]': 'property_types[]',
  'interestTypes[]': 'interest_types[]',
  'equipment[]': 'equipment[]',
  conditionDevelopment: 'condition_development',
  isNewBuilding: 'is_new_building',
};
