import { Storage } from '@portals/core-immobilien/src/utils';
import { ConfigProvider } from '@portals/sip-client-data/src/general/Config';

export const STORAGE_KEY_BOOKMARKS = 'bookmarks';

export const addBookmark = (estateId: string): boolean => {
  const bookmarks = getBookmarks();

  if (!bookmarkExists(estateId)) {
    bookmarks.push(estateId);

    return saveBookmarks(bookmarks);
  }

  return false;
};

export const removeBookmark = (estateId: string): boolean => {
  const bookmarks = getBookmarks();
  const bookmarkPosition = bookmarks.indexOf(estateId);

  if (bookmarkExists(estateId)) {
    bookmarks.splice(bookmarkPosition, 1);

    return saveBookmarks(bookmarks);
  }

  return false;
};

export const bookmarkExists = (estateId: string): boolean => {
  return getBookmarks().indexOf(estateId) > -1;
};

export const getBookmarks = (): string[] => {
  const bookmarks = Storage.get(STORAGE_KEY_BOOKMARKS);

  return bookmarks || [];
};

const saveBookmarks = (bookmarks: string[]): boolean => {
  try {
    Storage.set(STORAGE_KEY_BOOKMARKS, bookmarks);
  } catch (e) {
    return false;
  }

  return true;
};

export const isLocalStorageBookmarksEnabled = (): boolean =>
  ConfigProvider.getConfig().get('LOCALSTORAGE_BOOKMARKS') === '1';
