import { ApiClientProvider } from '@portals/sip-client-data/src/general/ApiClient';

import { UserEventProps } from '../types';
import { Logger } from '../utils';

export const clientSendUserEvent = async (userEvent: UserEventProps): Promise<boolean> => {
  try {
    await ApiClientProvider.getApiClient().sendUserEvent(userEvent);
    return true;
  } catch (e) {
    Logger.error({ Message: '[IMMOBILIEN] unable to send user event', e });
    return false;
  }
};
