import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const Room: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 16 17" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6692 0.966177C10.8308 1.09421 10.9251 1.28911 10.9251 1.49532V4.19531H13.2501C13.6229 4.19531 13.9251 4.49752 13.9251 4.87031V13.5703H15.1251C15.4979 13.5703 15.8001 13.8725 15.8001 14.2453C15.8001 14.6181 15.4979 14.9203 15.1251 14.9203H13.2501C12.8773 14.9203 12.5751 14.6181 12.5751 14.2453V5.54531H10.9251V15.7453C10.9251 15.9515 10.8308 16.1464 10.6692 16.2745C10.5075 16.4025 10.2962 16.4496 10.0955 16.4024L3.79671 14.9203H1.25007C0.877281 14.9203 0.575073 14.6181 0.575073 14.2453C0.575073 13.8725 0.877281 13.5703 1.25007 13.5703H3.20007V2.99532C3.20007 2.68208 3.41556 2.41001 3.72047 2.33826L10.0955 0.838262C10.2962 0.791032 10.5075 0.838147 10.6692 0.966177ZM9.57507 4.87031V2.34758L4.55007 3.52993V13.7107L9.57507 14.8931V4.87031ZM8.63752 8.2459C8.63752 7.89382 8.3521 7.6084 8.00002 7.6084C7.64794 7.6084 7.36252 7.89382 7.36252 8.2459V8.9959C7.36252 9.34798 7.64794 9.6334 8.00002 9.6334C8.3521 9.6334 8.63752 9.34798 8.63752 8.9959V8.2459Z"
      fill="black"
    />
  </SvgIcon>
);
