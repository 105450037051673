import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const Warning: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.1838 27.2922L17.2611 3.44678C17.0221 2.96887 16.5337 2.66699 15.9993 2.66699C15.465 2.66699 14.9766 2.96887 14.7376 3.44678L2.81488 27.2922C2.5963 27.7295 2.6197 28.2488 2.87673 28.6646C3.13376 29.0805 3.58777 29.3336 4.07665 29.3337H27.922C28.4109 29.3336 28.8649 29.0805 29.122 28.6646C29.379 28.2488 29.4024 27.7295 29.1838 27.2922ZM14.6658 11.998C14.6658 11.2615 15.2629 10.6644 15.9993 10.6644C16.7358 10.6644 17.3329 11.2615 17.3329 11.998V19.999C17.3329 20.7355 16.7358 21.3326 15.9993 21.3326C15.2629 21.3326 14.6658 20.7355 14.6658 19.999V11.998ZM14.3325 24.9997C14.3325 25.9203 15.0787 26.6666 15.9993 26.6666C16.9199 26.6666 17.6662 25.9203 17.6662 24.9997C17.6662 24.0791 16.9199 23.3328 15.9993 23.3328C15.0787 23.3328 14.3325 24.0791 14.3325 24.9997Z"
    />
  </SvgIcon>
);
