import { SvgIcon, SvgIconProps, SxProps, useTheme } from '@mui/material';
import type { Theme } from '@portals/core/src/themes/themes';
import React from 'react';

export const VideoPlaceholder: React.FC<SvgIconProps> = ({ sx = {}, ...props }) => {
  const theme = useTheme<Theme>();
  const combinedSx: SxProps = {
    ...({
      '& path:nth-of-type(1)': {
        fill: 'transparent',
        stroke: `${theme.palette.buttonGroup.colorDisabled}`,
      },
      '& path:nth-of-type(2)': {
        fill: 'transparent',
        stroke: `${theme.palette.buttonGroup.colorDisabled}`,
      },
      '& rect:nth-of-type(1)': {
        fill: 'transparent',
      },
      '& rect:nth-of-type(2)': {
        fill: `${theme.palette.background.default}`,
        stroke: `${theme.palette.background.default}`,
      },
      '& path:nth-of-type(3)': {
        fill: `${theme.palette.buttonGroup.colorDisabled}`,
      },
      '& path:nth-of-type(4)': {
        fill: `${theme.palette.buttonGroup.colorDisabled}`,
      },
    } as SxProps),
    ...sx,
  };

  return (
    <SvgIcon viewBox="0 0 656 300" sx={combinedSx} {...props}>
      <path
        d="M85.3672 40.0254C85.3672 20.9489 100.832 5.48438 119.908 5.48438H471.599C490.676 5.48438 506.14 20.9489 506.14 40.0254V259.832C506.14 278.909 490.676 294.373 471.599 294.373H119.908C100.832 294.373 85.3672 278.909 85.3672 259.832V40.0254Z"
        strokeWidth="10"
      />
      <path
        d="M521.675 135.444C512.648 141.687 512.648 155.029 521.675 161.272L625.954 233.379C636.368 240.58 650.585 233.127 650.585 220.466V76.2499C650.585 63.5889 636.368 56.1352 625.954 63.3362L521.675 135.444Z"
        strokeWidth="10"
      />
      <rect x="8.43491" y="72.9974" width="153.865" height="153.865" rx="76.9324" />
      <rect x="8.43491" y="72.9974" width="153.865" height="153.865" rx="76.9324" strokeWidth="15.7005" />
      <path d="M85.3732 207.502C73.9872 207.502 62.8569 204.126 53.3898 197.8C43.9228 191.474 36.5441 182.483 32.1868 171.964C27.8296 161.445 26.6896 149.87 28.9109 138.703C31.1322 127.535 36.615 117.278 44.6661 109.227C52.7172 101.176 62.9749 95.6927 74.1421 93.4714C85.3093 91.2501 96.8844 92.3902 107.404 96.7474C117.923 101.105 126.914 108.483 133.24 117.95C139.565 127.417 142.942 138.548 142.942 149.934C142.925 165.197 136.854 179.83 126.062 190.622C115.269 201.415 100.636 207.485 85.3732 207.502V207.502ZM85.3732 103.879C76.2644 103.879 67.3602 106.58 59.7865 111.641C52.2128 116.701 46.3099 123.894 42.8241 132.309C39.3383 140.725 38.4263 149.985 40.2033 158.919C41.9804 167.852 46.3667 176.058 52.8075 182.499C59.2484 188.94 67.4546 193.326 76.3883 195.104C85.3221 196.881 94.5821 195.969 102.998 192.483C111.413 188.997 118.606 183.094 123.666 175.52C128.727 167.947 131.428 159.042 131.428 149.934C131.414 137.723 126.558 126.017 117.924 117.383C109.29 108.749 97.5834 103.893 85.3732 103.879V103.879Z" />
      <path d="M70.918 128.935V170.914C70.9163 171.884 71.1888 172.834 71.7039 173.656C72.2191 174.478 72.9559 175.137 73.8296 175.559C74.7033 175.98 75.6782 176.145 76.642 176.037C77.6057 175.928 78.5191 175.549 79.2769 174.944L105.517 153.954C106.124 153.473 106.615 152.86 106.953 152.162C107.29 151.465 107.465 150.7 107.465 149.924C107.465 149.149 107.29 148.384 106.953 147.686C106.615 146.988 106.124 146.376 105.517 145.895L79.2769 124.917C78.5199 124.312 77.6076 123.933 76.6448 123.824C75.6821 123.715 74.7081 123.879 73.8348 124.299C72.9615 124.719 72.2245 125.377 71.7085 126.197C71.1925 127.017 70.9185 127.966 70.918 128.935V128.935Z" />
    </SvgIcon>
  );
};
