import { ConfigProvider } from '@portals/sip-client-data/src/general/Config';
import React, { createContext, ReactNode, useContext } from 'react';

export const IF6Context = createContext<boolean>(false);

export const useIF6Context = (): boolean => {
  return useContext(IF6Context);
};

export const IF6Provider = ({ children }: { children: ReactNode }): React.ReactElement => {
  const isIF6Scope = ConfigProvider.getConfig()?.get('APP_CONTEXT') === 'if6';

  return <IF6Context.Provider value={isIF6Scope}>{children}</IF6Context.Provider>;
};
