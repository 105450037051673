import { MarketingType } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { TFunction } from 'i18next';

import { ButtonOptionsProps } from '../../components/Buttons/ButtonGroupSelect/ButtonGroupSelect';

export const getAllMarketingTypes = (t: TFunction): ButtonOptionsProps[] => {
  return [
    {
      value: MarketingType.BUY,
      label: t('marketingType.buy'),
    },
    {
      value: MarketingType.RENT,
      label: t('marketingType.rent'),
    },
  ];
};
