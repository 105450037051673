import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const MapMarker: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon width="41" height="48" viewBox="0 0 41 48" {...props}>
    <g filter="url(#filter0_d_6225_30045)">
      <path
        d="M20.4006 0.3125C11.793 0.3125 4.78906 7.65313 4.78906 16.677C4.78906 28.8959 18.9814 39.0041 19.5789 39.4273C19.8074 39.5891 20.0926 39.6875 20.4006 39.6875C20.7086 39.6875 20.9938 39.5905 21.2266 39.4245L21.2223 39.4273C21.8198 39.0041 36.0121 28.8959 36.0121 16.677C36.0121 7.65313 29.0082 0.3125 20.4006 0.3125Z"
        fill="#FF0000"
      />
      <path
        d="M20.9333 39.0194L20.9334 39.0195C20.7838 39.1252 20.6007 39.1875 20.4006 39.1875C20.1998 39.1875 20.0148 39.1233 19.8677 39.0192C19.5809 38.816 15.9199 36.2093 12.3349 32.1448C8.73741 28.0662 5.28906 22.6012 5.28906 16.677C5.28906 7.90683 12.091 0.8125 20.4006 0.8125C28.7102 0.8125 35.5121 7.90683 35.5121 16.677C35.5121 22.6012 32.0638 28.0662 28.4663 32.1448C24.8805 36.2102 21.2187 38.8172 20.9333 39.0194Z"
        stroke="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_6225_30045"
        x="0.789062"
        y="0.3125"
        width="39.2266"
        height="47.375"
        filterUnits="userSpaceOnUse"
      >
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6225_30045" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6225_30045" result="shape" />
      </filter>
    </defs>
  </SvgIcon>
);
