const Storage = {
  isEnabled(): boolean {
    try {
      return typeof window !== 'undefined' && !!window.localStorage;
    } catch (e) {
      return false;
    }
  },

  get(key: string) {
    if (!this.isEnabled()) {
      return;
    }

    const data = window.localStorage.getItem(key);
    return JSON.parse(data);
  },

  set(key: string, value = undefined): void {
    if (!this.isEnabled()) {
      return;
    }
    if (!value) {
      return this.remove(key);
    }

    window.localStorage.setItem(key, JSON.stringify(value));
  },

  remove(key: string): void {
    if (!this.isEnabled()) {
      return;
    }
    window.localStorage.removeItem(key);
  },
};

export default Storage;
