import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const Area: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.61997 1.5C9.06768 1.5 8.61997 1.94772 8.61997 2.5C8.61997 3.05228 9.06768 3.5 9.61997 3.5H21.12V15C21.12 15.5523 21.5677 16 22.12 16C22.6723 16 23.12 15.5523 23.12 15V2.5C23.12 1.94772 22.6723 1.5 22.12 1.5H9.61997ZM1.29289 5.67781C0.902369 5.28729 0.902369 4.65412 1.29289 4.2636L3.26357 2.29292C3.65409 1.9024 4.28726 1.9024 4.67778 2.29292L6.64846 4.2636C7.03898 4.65412 7.03898 5.28729 6.64846 5.67781C6.25793 6.06833 5.62477 6.06833 5.23425 5.67781L4.96958 5.41314V19.6507H19.6551L19.3928 19.3884C19.0023 18.9979 19.0023 18.3647 19.3928 17.9742C19.7834 17.5837 20.4165 17.5837 20.8071 17.9742L22.7777 19.9449C23.1683 20.3354 23.1683 20.9686 22.7777 21.3591L20.8071 23.3298C20.4165 23.7203 19.7834 23.7203 19.3928 23.3298C19.0023 22.9392 19.0023 22.3061 19.3928 21.9155L19.6577 21.6507H3.96958C3.41729 21.6507 2.96958 21.203 2.96958 20.6507V5.41534L2.70711 5.67781C2.31658 6.06833 1.68342 6.06833 1.29289 5.67781Z"
      fill="black"
    />
    <path
      d="M7.1416 15.1992V9.48984H8.59629L8.64004 10.1461C8.79316 9.92005 8.99915 9.73594 9.25801 9.59375C9.51686 9.44792 9.82676 9.375 10.1877 9.375C10.5814 9.375 10.9059 9.44792 11.1611 9.59375C11.42 9.73958 11.6132 9.95104 11.7408 10.2281C11.883 10.0385 12.0452 9.8763 12.2275 9.74141C12.4135 9.60651 12.6104 9.51172 12.8182 9.45703C13.0296 9.40234 13.2356 9.375 13.4361 9.375C13.8809 9.375 14.2419 9.46979 14.5189 9.65937C14.7997 9.84531 14.9874 10.0841 15.0822 10.3758C15.177 10.6674 15.2244 10.9609 15.2244 11.2562V15.1992H13.6822V11.5953C13.6822 11.2745 13.6239 11.0411 13.5072 10.8953C13.3906 10.7495 13.2028 10.6766 12.9439 10.6766C12.7981 10.6766 12.6632 10.7112 12.5393 10.7805C12.4189 10.8461 12.315 10.9354 12.2275 11.0484C12.14 11.1615 12.0726 11.2945 12.0252 11.4477C11.9778 11.5971 11.9541 11.7539 11.9541 11.918V15.1992H10.4119V11.5953C10.4119 11.2745 10.3536 11.0411 10.2369 10.8953C10.1202 10.7495 9.93249 10.6766 9.67363 10.6766C9.5278 10.6766 9.3929 10.7112 9.26895 10.7805C9.14863 10.8461 9.04473 10.9354 8.95723 11.0484C8.86973 11.1615 8.80228 11.2945 8.75488 11.4477C8.70749 11.5971 8.68379 11.7539 8.68379 11.918V15.1992H7.1416Z"
      fill="black"
    />
    <path
      d="M15.9588 11.907V10.9227C16.1374 10.7768 16.2978 10.6492 16.44 10.5398C16.5858 10.4268 16.759 10.2865 16.9595 10.1187C17.1601 9.9474 17.3205 9.81068 17.4408 9.70859C17.7616 9.43151 17.964 9.25469 18.0478 9.17812C18.1317 9.09792 18.2137 8.98854 18.2939 8.85C18.3741 8.70781 18.4142 8.57656 18.4142 8.45625C18.4142 8.29948 18.3559 8.17552 18.2392 8.08438C18.1262 7.98958 17.9275 7.94219 17.6431 7.94219C17.4426 7.94219 17.2384 7.975 17.0306 8.04062C16.8265 8.10625 16.6569 8.17552 16.522 8.24844C16.3871 8.32135 16.3051 8.36875 16.2759 8.39062V7.25859C16.4437 7.18203 16.6114 7.11823 16.7791 7.06719C16.9504 7.0125 17.1382 6.96875 17.3424 6.93594C17.5502 6.90312 17.7434 6.88672 17.922 6.88672C19.1835 6.88672 19.8142 7.37344 19.8142 8.34687C19.8142 8.63854 19.7395 8.90833 19.59 9.15625C19.4442 9.40052 19.2655 9.61563 19.0541 9.80156C18.8463 9.98385 18.6621 10.1406 18.5017 10.2719C18.4798 10.2901 18.2374 10.4833 17.7744 10.8516H19.9947V11.907H15.9588Z"
      fill="black"
    />
  </SvgIcon>
);
