import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

// todo: we need a 24x24 icon: https://www.figma.com/file/oO3iq15338xE8MeGp3URQm/%5BSparkasse%5D-SIP-Sandbox?node-id=3315%3A154089
export const Print: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <path d="M28 11H26V4C26 2.895 25.105 2 24 2H8C6.895 2 6 2.895 6 4V11H4C2.895 11 2 11.895 2 13V24C2 25.105 2.895 26 4 26H4.5V24H4V13H28V24H27.5V26H28C29.105 26 30 25.105 30 24V13C30 11.895 29.105 11 28 11ZM24 11H8V4H24V11Z" />
    <path d="M24 20V28H8V20H24ZM8 18C6.895 18 6 18.895 6 20V28C6 29.105 6.895 30 8 30H24C25.105 30 26 29.105 26 28V20C26 18.895 25.105 18 24 18H8Z" />
    <path d="M21 23H11C10.724 23 10.5 22.776 10.5 22.5C10.5 22.224 10.724 22 11 22H21C21.276 22 21.5 22.224 21.5 22.5C21.5 22.776 21.276 23 21 23Z" />
    <path d="M21 26H11C10.724 26 10.5 25.776 10.5 25.5C10.5 25.224 10.724 25 11 25H21C21.276 25 21.5 25.224 21.5 25.5C21.5 25.776 21.276 26 21 26Z" />
  </SvgIcon>
);
