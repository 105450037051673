import { find, get } from 'lodash-es';

export enum SortBy {
  DISTANCE_SMALLEST = 'distance_asc',
  PRICE_LOWEST = 'price_asc',
  PRICE_HIGHEST = 'price_desc',
  PROPERTY_NEWEST = 'date_desc',
}

export const getSortByOptions = (t) => {
  return [
    {
      value: SortBy.DISTANCE_SMALLEST,
      label: t('sortOptions.distanceSmallest'),
    },
    {
      value: SortBy.PRICE_LOWEST,
      label: t('sortOptions.priceLowest'),
    },
    {
      value: SortBy.PRICE_HIGHEST,
      label: t('sortOptions.priceHighest'),
    },
    {
      value: SortBy.PROPERTY_NEWEST,
      label: t('sortOptions.propertyNewest'),
    },
  ];
};

export const getSortByLabelFromOptionsByValue = (t, value) => {
  const selectedOption = find(getSortByOptions(t), { value });
  return get(selectedOption, 'label', '');
};
