import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const CircleWithDots: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M12 22.5C6.201 22.5 1.5 17.799 1.5 12C1.5 6.201 6.201 1.5 12 1.5C17.799 1.5 22.5 6.201 22.5 12C22.4932 17.7967 17.7967 22.4932 12.0007 22.5H12ZM12 3C7.02975 3 3 7.02975 3 12C3 16.9703 7.02975 21 12 21C16.9703 21 21 16.9703 21 12C20.9947 7.032 16.968 3.00525 12.0007 3H12Z" />
    <path d="M8.966 11.975C8.966 12.513 8.526 12.95 7.983 12.95C7.44 12.95 7 12.513 7 11.975C7 11.437 7.44 11 7.983 11C8.526 11 8.966 11.437 8.966 11.975Z" />
    <path d="M12.916 11.975C12.916 12.513 12.476 12.95 11.933 12.95C11.39 12.95 10.95 12.513 10.95 11.975C10.95 11.437 11.39 11 11.933 11C12.476 11 12.916 11.437 12.916 11.975Z" />
    <path d="M16.8669 11.975C16.8669 12.513 16.4269 12.95 15.8839 12.95C15.3409 12.95 14.9009 12.513 14.9009 11.975C14.9009 11.437 15.3409 11 15.8839 11C16.4269 11 16.8669 11.437 16.8669 11.975Z" />
  </SvgIcon>
);
