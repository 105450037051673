import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const ObjectData: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M7.5 10.5H9.375C9.789 10.5 10.125 10.164 10.125 9.75C10.125 9.336 9.789 9 9.375 9H7.5C7.086 9 6.75 9.336 6.75 9.75C6.75 10.164 7.086 10.5 7.5 10.5Z" />
    <path d="M7.5 12.75C7.086 12.75 6.75 13.086 6.75 13.5C6.75 13.914 7.086 14.25 7.5 14.25H9.74475C9.93975 13.677 10.179 13.1812 10.47 12.7207L10.4528 12.75H7.5Z" />
    <path d="M7.5 16.5C7.086 16.5 6.75 16.836 6.75 17.25C6.75 17.664 7.086 18 7.5 18H9.537C9.435 17.5493 9.37575 17.0317 9.375 16.5007V16.5H7.5Z" />
    <path d="M10.9822 21H4.5V3H12.75V6.75C12.75 7.57875 13.4213 8.25 14.25 8.25H18V9.537C18.57 9.66375 19.071 9.8385 19.5427 10.065L19.5 10.0463V7.5C19.5 7.293 19.416 7.1055 19.2803 6.96975L14.0303 1.71975C13.8945 1.584 13.707 1.5 13.5 1.5H4.5C3.67125 1.5 3 2.17125 3 3V21C3 21.8288 3.67125 22.5 4.5 22.5H12.675C12.0217 22.08 11.4637 21.5835 10.992 21.012L10.9822 21ZM14.25 4.0605L16.9395 6.75H14.25V4.0605Z" />
    <path d="M16.5 10.5C13.1865 10.5 10.5 13.1865 10.5 16.5C10.5 19.8135 13.1865 22.5 16.5 22.5C19.8135 22.5 22.5 19.8135 22.5 16.5C22.4963 13.188 19.812 10.5037 16.5 10.5ZM16.5 21C14.0145 21 12 18.9855 12 16.5C12 14.0145 14.0145 12 16.5 12C18.9855 12 21 14.0145 21 16.5C20.9978 18.984 18.984 20.9978 16.5 21Z" />
    <path d="M15.7488 18.7498C15.5418 18.7498 15.3543 18.6658 15.2186 18.53L13.7186 17.03C13.5881 16.895 13.5078 16.7113 13.5078 16.5088C13.5078 16.0948 13.8438 15.7588 14.2578 15.7588C14.4603 15.7588 14.6441 15.839 14.7791 15.9695L15.7488 16.9393L18.2186 14.4695C18.3536 14.339 18.5373 14.2588 18.7398 14.2588C19.1538 14.2588 19.4898 14.5948 19.4898 15.0088C19.4898 15.2113 19.4096 15.395 19.2791 15.53L16.2791 18.53C16.1433 18.6658 15.9558 18.7498 15.7488 18.7498Z" />
  </SvgIcon>
);
