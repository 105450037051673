// ported mapping from
// https://gitlab.sfp.berlin/products/sparkasse.de/www-sparkasse-de/-/blob/develop/app/ui.apps/src/main/content/jcr_root/apps/s-ip/components/shared/sip-track-event-mapping.ts
export const trackEventMapping: Record<string, { cat: string; act: string; label?: string; addBlz?: boolean }> = {
  expo_views: { cat: 'Expose', act: 'exposeview' },
  expose_download: { cat: 'Expose', act: 'drucken' },
  legalnotice_click: { cat: 'Expose', act: 'anbieterimpressum_anzeigen' },
  map_click: { cat: 'Expose', act: 'karte_anzeigen' },
  contactBroker_success: { cat: 'Maklerkontakt', act: 'abgeschickt', addBlz: true },
  contactBroker_select: { cat: 'Maklerkontakt', act: 'anzeigen', addBlz: true },
  wishlist_remove: { cat: 'Merkzettel', act: 'entfernen' },
  wishlist_add: { cat: 'Merkzettel', act: 'hinzufuegen' },
};
