import { buildQueries, Matcher, MatcherOptions, queryHelpers } from '@testing-library/react';

const queryAllByDataId = (container: HTMLElement, id: Matcher, options?: MatcherOptions | undefined): HTMLElement[] =>
  queryHelpers.queryAllByAttribute('data-id', container, id, options);

const getMultipleError = (c, dataIdValue) => `Found multiple elements with the data-id attribute of: ${dataIdValue}`;
const getMissingError = (c, dataIdValue) => `Unable to find an element with the data-id attribute of: ${dataIdValue}`;

const [queryByDataId, getAllByDataId, getByDataId, findAllByDataId, findByDataId] = buildQueries(
  queryAllByDataId,
  getMultipleError,
  getMissingError
);

export { queryByDataId, queryAllByDataId, getByDataId, getAllByDataId, findAllByDataId, findByDataId };
