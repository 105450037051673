import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const Foot: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 18 18" {...props}>
    <path d="M13.0926 9.2001C13.0262 9.07264 12.9162 8.97328 12.7826 8.9201L10.9926 7.9201L9.60262 6.3601C9.54999 6.31002 9.48909 6.26942 9.42262 6.2401L9.32262 6.2201C9.25004 6.20528 9.17521 6.20528 9.10262 6.2201L6.61262 7.0501C6.53536 7.05904 6.46238 7.09032 6.40262 7.1401C6.3594 7.19511 6.33176 7.26073 6.32262 7.3301L4.98262 9.3801C4.90465 9.51269 4.87964 9.66985 4.91262 9.8201C4.93152 9.8937 4.96521 9.96269 5.01163 10.0229C5.05805 10.083 5.11622 10.1331 5.18262 10.1701C5.27181 10.2167 5.37225 10.2375 5.47262 10.2301C5.54928 10.2156 5.6216 10.1838 5.68401 10.1369C5.74642 10.0901 5.79726 10.0296 5.83262 9.9601L7.02262 8.1001L8.43262 7.5201L7.65262 10.5201C7.63259 10.6056 7.63259 10.6946 7.65262 10.7801C7.67916 10.8703 7.7272 10.9526 7.79262 11.0201L9.53262 12.9201L10.7526 15.9701C10.7952 16.0729 10.8642 16.1626 10.9526 16.2301C11.0471 16.2942 11.1584 16.329 11.2726 16.3301H11.4826C11.6203 16.268 11.7305 16.1577 11.7926 16.0201C11.8477 15.8821 11.8477 15.7281 11.7926 15.5901L10.5626 12.5201L10.4426 12.3401L8.82262 10.5201L9.49262 8.0401L10.2326 8.8401C10.2997 8.91312 10.3815 8.97107 10.4726 9.0101L12.4026 10.0101H12.5926C12.7095 10.0061 12.8232 9.97165 12.9226 9.9101C13.0169 9.83772 13.0895 9.74087 13.1326 9.6301C13.1751 9.48696 13.1608 9.33294 13.0926 9.2001Z" />
    <path d="M7.70767 12.0786C7.65782 12.0243 7.6006 11.9771 7.53767 11.9386C7.47113 11.8978 7.39561 11.8737 7.31767 11.8686C7.24467 11.8589 7.17068 11.8589 7.09767 11.8686C7.02951 11.8919 6.9654 11.9256 6.90767 11.9686C6.84986 12.0179 6.79935 12.0751 6.75767 12.1386L5.31767 15.3286C5.2751 15.3915 5.24771 15.4634 5.23767 15.5386C5.22286 15.6112 5.22286 15.6861 5.23767 15.7586C5.25669 15.8316 5.29075 15.8997 5.33767 15.9586C5.38211 16.021 5.44027 16.0723 5.50767 16.1086C5.59446 16.153 5.69023 16.1769 5.78767 16.1786C5.88636 16.1767 5.98284 16.1491 6.06767 16.0986C6.15348 16.0508 6.22574 15.982 6.27767 15.8986L7.70767 12.7086C7.75853 12.6547 7.79618 12.5896 7.81767 12.5186C7.83249 12.4461 7.83249 12.3712 7.81767 12.2986C7.79842 12.2178 7.76078 12.1425 7.70767 12.0786Z" />
    <path d="M10.0078 5.62891C10.4034 5.62891 10.7901 5.51161 11.119 5.29185C11.4479 5.07208 11.7042 4.75973 11.8556 4.39427C12.0069 4.02882 12.0466 3.62669 11.9694 3.23873C11.8922 2.85076 11.7017 2.4944 11.422 2.21469C11.1423 1.93499 10.786 1.74451 10.398 1.66734C10.01 1.59017 9.6079 1.62977 9.24245 1.78115C8.87699 1.93252 8.56464 2.18887 8.34487 2.51777C8.12511 2.84667 8.00781 3.23334 8.00781 3.62891C8.00781 4.15934 8.21853 4.66805 8.5936 5.04312C8.96867 5.41819 9.47738 5.62891 10.0078 5.62891ZM10.0078 2.83891C10.172 2.83891 10.3324 2.88759 10.4689 2.97879C10.6054 3.06999 10.7118 3.19962 10.7746 3.35128C10.8375 3.50294 10.8539 3.66983 10.8219 3.83083C10.7898 3.99184 10.7108 4.13973 10.5947 4.25581C10.4786 4.37188 10.3307 4.45093 10.1697 4.48296C10.0087 4.51498 9.84185 4.49855 9.69019 4.43573C9.53852 4.37291 9.40889 4.26652 9.31769 4.13003C9.22649 3.99354 9.17781 3.83307 9.17781 3.66891C9.17781 3.55991 9.19928 3.45198 9.24099 3.35128C9.2827 3.25058 9.34384 3.15908 9.42091 3.08201C9.57657 2.92635 9.78768 2.83891 10.0078 2.83891Z" />
  </SvgIcon>
);
