import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const Finance: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M14.625 6.75391C10.2758 6.75391 6.75 10.2797 6.75 14.6289C6.75 18.9782 10.2758 22.5039 14.625 22.5039C18.9742 22.5039 22.5 18.9782 22.5 14.6289C22.5 10.2797 18.9742 6.75391 14.625 6.75391ZM14.625 21.0039C11.1045 21.0039 8.25 18.1494 8.25 14.6289C8.25 11.1084 11.1045 8.25391 14.625 8.25391C18.1455 8.25391 21 11.1084 21 14.6289C21 18.1494 18.1455 21.0039 14.625 21.0039Z" />
    <path d="M7.43484 9.24434C7.20009 9.14234 7.00284 8.99534 6.84534 8.81309L6.84384 8.81084L8.23509 8.30459C8.29659 8.24234 8.36259 8.18534 8.42559 8.12459L8.38359 7.42484L6.42984 8.13584L6.35484 7.92509L6.30984 7.80134C6.29634 7.76759 6.28434 7.72784 6.27534 7.68659L6.27459 7.68134L8.39259 6.90734L8.34384 6.09659L6.16884 6.88709C6.16809 6.87209 6.16809 6.85484 6.16809 6.83759C6.16809 6.50759 6.27684 6.20384 6.46134 5.95934L6.45834 5.96309C6.65784 5.71859 6.91959 5.53259 7.22034 5.42909L7.23234 5.42534C7.39809 5.36009 7.59684 5.30234 7.80159 5.26184L7.82409 5.25809C7.94034 5.23484 8.07384 5.22134 8.21034 5.22134C8.24034 5.22134 8.27034 5.22209 8.30034 5.22359H8.29584L8.28759 4.25684C8.06559 4.25759 7.84809 4.27784 7.63734 4.31684L7.65984 4.31309C7.34559 4.36859 7.06884 4.44659 6.80334 4.54859L6.83484 4.53809C6.54834 4.64309 6.30084 4.77359 6.07434 4.93259L6.08484 4.92584C5.85534 5.08634 5.66034 5.27834 5.50209 5.49809L5.49759 5.50559C5.33934 5.72609 5.21634 5.98409 5.14359 6.26234L5.13984 6.27884C5.09409 6.46484 5.06709 6.67784 5.06709 6.89759C5.06709 7.03259 5.07684 7.16534 5.09634 7.29509L5.09484 7.28009L4.55859 7.47584V8.30084L5.25084 8.05109C5.26059 8.09759 5.27259 8.13734 5.28759 8.17634L5.28609 8.17109L5.33109 8.29484L5.40609 8.50559L4.98609 8.65559V9.48059L5.76459 9.19709C5.93859 9.48434 6.15234 9.72809 6.40209 9.92909L6.40734 9.93284C6.52359 10.0228 6.65484 10.1046 6.79434 10.1728L6.80784 10.1788C7.01859 9.81959 7.22484 9.51509 7.44984 9.22409L7.43409 9.24509L7.43484 9.24434Z" />
    <path d="M3.273 9.04357C3.1005 8.58532 3.0015 8.05582 3.0015 7.50307C3.0015 5.57107 4.2195 3.92257 5.9295 3.28507L5.96025 3.27457C6.4185 3.10207 6.948 3.00232 7.50075 3.00232C9.4335 3.00232 11.0813 4.22032 11.7188 5.93107L11.7292 5.96182C11.745 6.00682 11.7562 6.05257 11.7712 6.09832C12.1823 5.95507 12.6735 5.83282 13.179 5.75257L13.2292 5.74582C13.1993 5.64682 13.1737 5.54782 13.1385 5.44582C12.276 3.12232 10.0778 1.49707 7.5 1.49707C4.1865 1.49707 1.5 4.18357 1.5 7.49707C1.5 10.1843 3.26625 12.4591 5.7015 13.2226L5.74425 13.2338C5.83125 12.6781 5.95425 12.1876 6.117 11.7143L6.09825 11.7766C4.77825 11.3423 3.74925 10.3441 3.28275 9.07432L3.273 9.04357Z" />
    <path d="M11.616 15.6538H10.5742L10.7482 14.9608H11.532C11.5252 14.876 11.5215 14.792 11.5207 14.7073V14.4425C11.5207 14.4425 11.5207 14.4425 11.5207 14.4418C11.5207 14.2303 11.5327 14.0218 11.5567 13.817L11.5545 13.8418H10.5742L10.7482 13.1488H11.6692C11.9835 11.627 13.3125 10.499 14.9047 10.499C14.9602 10.499 15.015 10.5005 15.0697 10.5028H15.0622C15.0787 10.5028 15.0982 10.5028 15.1185 10.5028C15.8092 10.5028 16.4677 10.6408 17.0685 10.8905L17.0347 10.8778L16.6177 12.1453C16.5127 12.101 16.383 12.0568 16.2502 12.0215L16.2285 12.0163C15.9367 11.9368 15.594 11.879 15.2422 11.8535L15.225 11.8528C15.177 11.8483 15.1215 11.846 15.066 11.846C14.244 11.846 13.5472 12.386 13.3125 13.1308L13.3087 13.1435H16.239L16.053 13.8365H13.1715C13.1467 14.009 13.1302 14.2123 13.1265 14.4178V14.423C13.1265 14.432 13.1265 14.4433 13.1265 14.4545C13.1265 14.6323 13.1385 14.807 13.1625 14.978L13.1602 14.9585H15.6622L15.4822 15.6515H13.251C13.2907 16.5995 14.0692 17.3533 15.024 17.3533C15.0495 17.3533 15.0757 17.3525 15.1012 17.3518H15.0975H15.1665C15.1912 17.3525 15.2197 17.3525 15.249 17.3525C15.7627 17.3525 16.2555 17.2633 16.713 17.0998L16.6822 17.1095L17.094 18.389C16.4602 18.6125 15.729 18.746 14.9685 18.755H14.964C13.425 18.7535 11.9767 17.9758 11.6167 15.6538H11.616Z" />
  </SvgIcon>
);
