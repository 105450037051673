import { split, toLower } from 'lodash-es';

const getSuffix = (value: string): string => {
  return toLower(split(value, '.')[-1]);
};

const getImageTpe = (value) => {
  const suffix = getSuffix(value);
  switch (suffix) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    default:
      return '';
  }
};

export const generateImageUrls = (
  imageUrl: string
): { webpUrl?: string; defaultUrl: string; defaultImageType: string } => {
  const result = {
    webpUrl: null,
    defaultUrl: imageUrl,
    defaultImageType: getImageTpe(imageUrl),
  };

  if (getSuffix(imageUrl) === 'webp') {
    result.webpUrl = imageUrl;
    result.defaultUrl = imageUrl.replace(/\.webp$/, '.jpg');
    result.defaultImageType = getImageTpe(result.defaultUrl);
  }

  return result;
};
