import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const House: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M21.75 21H20.25V2.25C20.25 1.836 19.914 1.5 19.5 1.5C19.4468 1.5 19.395 1.50525 19.3455 1.51575L19.35 1.515L4.35 4.515C4.005 4.587 3.75 4.8885 3.75 5.25V21H2.25C1.836 21 1.5 21.336 1.5 21.75C1.5 22.164 1.836 22.5 2.25 22.5H21.75C22.164 22.5 22.5 22.164 22.5 21.75C22.5 21.336 22.164 21 21.75 21ZM5.25 5.86425L18.75 3.16425V21H14.25V15.75C14.25 15.336 13.914 15 13.5 15H10.5C10.086 15 9.75 15.336 9.75 15.75V21H5.25V5.86425ZM12.75 21H11.25V16.5H12.75V21Z" />
    <path d="M13.125 7.5H13.875C14.082 7.5 14.25 7.668 14.25 7.875V8.625C14.25 8.832 14.082 9 13.875 9H13.125C12.918 9 12.75 8.832 12.75 8.625V7.875C12.75 7.668 12.918 7.5 13.125 7.5Z" />
    <path d="M10.125 7.5H10.875C11.082 7.5 11.25 7.668 11.25 7.875V8.625C11.25 8.832 11.082 9 10.875 9H10.125C9.918 9 9.75 8.832 9.75 8.625V7.875C9.75 7.668 9.918 7.5 10.125 7.5Z" />
    <path d="M7.125 7.5H7.875C8.082 7.5 8.25 7.668 8.25 7.875V8.625C8.25 8.832 8.082 9 7.875 9H7.125C6.918 9 6.75 8.832 6.75 8.625V7.875C6.75 7.668 6.918 7.5 7.125 7.5Z" />
    <path d="M7.125 11.25H7.875C8.082 11.25 8.25 11.418 8.25 11.625V12.375C8.25 12.582 8.082 12.75 7.875 12.75H7.125C6.918 12.75 6.75 12.582 6.75 12.375V11.625C6.75 11.418 6.918 11.25 7.125 11.25Z" />
    <path d="M16.125 7.5H16.875C17.082 7.5 17.25 7.668 17.25 7.875V8.625C17.25 8.832 17.082 9 16.875 9H16.125C15.918 9 15.75 8.832 15.75 8.625V7.875C15.75 7.668 15.918 7.5 16.125 7.5Z" />
    <path d="M13.125 11.25H13.875C14.082 11.25 14.25 11.418 14.25 11.625V12.375C14.25 12.582 14.082 12.75 13.875 12.75H13.125C12.918 12.75 12.75 12.582 12.75 12.375V11.625C12.75 11.418 12.918 11.25 13.125 11.25Z" />
    <path d="M10.125 11.25H10.875C11.082 11.25 11.25 11.418 11.25 11.625V12.375C11.25 12.582 11.082 12.75 10.875 12.75H10.125C9.918 12.75 9.75 12.582 9.75 12.375V11.625C9.75 11.418 9.918 11.25 10.125 11.25Z" />
  </SvgIcon>
);
