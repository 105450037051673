import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const ForeclosureData: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M20.25 3.75H16.5V3C16.5 2.586 16.164 2.25 15.75 2.25C15.336 2.25 15 2.586 15 3V3.75H9V3C9 2.586 8.664 2.25 8.25 2.25C7.836 2.25 7.5 2.586 7.5 3V3.75H3.75C2.92125 3.75 2.25 4.42125 2.25 5.25V20.25C2.25 21.0788 2.92125 21.75 3.75 21.75H20.25C21.0788 21.75 21.75 21.0788 21.75 20.25V5.25C21.75 4.42125 21.0788 3.75 20.25 3.75ZM7.5 5.25V6C7.5 6.414 7.836 6.75 8.25 6.75C8.664 6.75 9 6.414 9 6V5.25H15V6C15 6.414 15.336 6.75 15.75 6.75C16.164 6.75 16.5 6.414 16.5 6V5.25H20.25V8.25H3.75V5.25H7.5ZM3.75 20.25V9.75H20.25V20.25H3.75Z" />
    <path d="M11.6242 17.25H12.3757C12.5827 17.25 12.75 17.4173 12.75 17.6243V18.3757C12.75 18.5827 12.5827 18.75 12.3757 18.75H11.6242C11.4172 18.75 11.25 18.5827 11.25 18.3757V17.6243C11.25 17.4173 11.4172 17.25 11.6242 17.25Z" />
    <path d="M14.6243 17.25H15.3757C15.5827 17.25 15.75 17.4173 15.75 17.6243V18.3757C15.75 18.5827 15.5827 18.75 15.3757 18.75H14.6243C14.4173 18.75 14.25 18.5827 14.25 18.3757V17.6243C14.25 17.4173 14.4173 17.25 14.6243 17.25Z" />
    <path d="M8.62425 17.25H9.37575C9.58275 17.25 9.75 17.4173 9.75 17.6243V18.3757C9.75 18.5827 9.58275 18.75 9.37575 18.75H8.62425C8.41725 18.75 8.25 18.5827 8.25 18.3757V17.6243C8.25 17.4173 8.41725 17.25 8.62425 17.25Z" />
    <path d="M11.6242 14.25H12.3757C12.5827 14.25 12.75 14.4173 12.75 14.6243V15.3757C12.75 15.5827 12.5827 15.75 12.3757 15.75H11.6242C11.4172 15.75 11.25 15.5827 11.25 15.3757V14.6243C11.25 14.4173 11.4172 14.25 11.6242 14.25Z" />
    <path d="M14.6243 14.25H15.3757C15.5827 14.25 15.75 14.4173 15.75 14.6243V15.3757C15.75 15.5827 15.5827 15.75 15.3757 15.75H14.6243C14.4173 15.75 14.25 15.5827 14.25 15.3757V14.6243C14.25 14.4173 14.4173 14.25 14.6243 14.25Z" />
    <path d="M8.62425 14.25H9.37575C9.58275 14.25 9.75 14.4173 9.75 14.6243V15.3757C9.75 15.5827 9.58275 15.75 9.37575 15.75H8.62425C8.41725 15.75 8.25 15.5827 8.25 15.3757V14.6243C8.25 14.4173 8.41725 14.25 8.62425 14.25Z" />
    <path d="M5.62425 17.25H6.37575C6.58275 17.25 6.75 17.4173 6.75 17.6243V18.3757C6.75 18.5827 6.58275 18.75 6.37575 18.75H5.62425C5.41725 18.75 5.25 18.5827 5.25 18.3757V17.6243C5.25 17.4173 5.41725 17.25 5.62425 17.25Z" />
    <path d="M5.62425 14.25H6.37575C6.58275 14.25 6.75 14.4173 6.75 14.6243V15.3757C6.75 15.5827 6.58275 15.75 6.37575 15.75H5.62425C5.41725 15.75 5.25 15.5827 5.25 15.3757V14.6243C5.25 14.4173 5.41725 14.25 5.62425 14.25Z" />
    <path d="M11.6242 11.25H12.3757C12.5827 11.25 12.75 11.4172 12.75 11.6242V12.3757C12.75 12.5827 12.5827 12.75 12.3757 12.75H11.6242C11.4172 12.75 11.25 12.5827 11.25 12.3757V11.6242C11.25 11.4172 11.4172 11.25 11.6242 11.25Z" />
    <path d="M14.6243 11.25H15.3757C15.5827 11.25 15.75 11.4172 15.75 11.6242V12.3757C15.75 12.5827 15.5827 12.75 15.3757 12.75H14.6243C14.4173 12.75 14.25 12.5827 14.25 12.3757V11.6242C14.25 11.4172 14.4173 11.25 14.6243 11.25Z" />
    <path d="M17.6243 14.25H18.3757C18.5827 14.25 18.75 14.4173 18.75 14.6243V15.3757C18.75 15.5827 18.5827 15.75 18.3757 15.75H17.6243C17.4173 15.75 17.25 15.5827 17.25 15.3757V14.6243C17.25 14.4173 17.4173 14.25 17.6243 14.25Z" />
    <path d="M17.6243 11.25H18.3757C18.5827 11.25 18.75 11.4172 18.75 11.6242V12.3757C18.75 12.5827 18.5827 12.75 18.3757 12.75H17.6243C17.4173 12.75 17.25 12.5827 17.25 12.3757V11.6242C17.25 11.4172 17.4173 11.25 17.6243 11.25Z" />
    <path d="M8.62425 11.25H9.37575C9.58275 11.25 9.75 11.4172 9.75 11.6242V12.3757C9.75 12.5827 9.58275 12.75 9.37575 12.75H8.62425C8.41725 12.75 8.25 12.5827 8.25 12.3757V11.6242C8.25 11.4172 8.41725 11.25 8.62425 11.25Z" />
  </SvgIcon>
);
