import { setLocale } from 'yup';

/* eslint-disable no-template-curly-in-string */
setLocale({
  mixed: {
    default: 'Die Eingabe ist ungültig.',
    required: 'Dies ist ein Pflichtfeld.',
    oneOf: 'Die Eingabe muss einem der folgenden Werte entsprechen: ${values}.',
    notOneOf: 'Die Eingabe darf keinem der folgenden Werte entsprechen: ${values}.',
    typeError: 'Die Eingabe konnte auf keinen Typ validiert werden.',
    notType: 'Diese Eingabe ist nicht erlaubt.',
  },
  string: {
    length: 'Die Eingabe muss genau ${length} Zeichen lang sein.',
    min: 'Die Eingabe muss mindestens ${min} Zeichen lang sein.',
    max: 'Die Eingabe  darf höchstens ${max} Zeichen lang sein.',
    matches: 'Die Eingabe muss wie folgt aussehen: "${regex}".',
    email: 'Die Eingabe muss eine gültige E-Mail-Adresse enthalten.',
    url: 'Die Eingabe muss eine gültige URL sein.',
    trim: 'Die Eingabe darf keine Leerzeichen am Anfang oder Ende enthalten.',
    lowercase: 'Die Eingabe darf nur Kleinschreibung enthalten.',
    uppercase: 'Die Eingabe darf nur Großschreibung enthalten.',
  },
  number: {
    min: 'Die Eingabe muss größer oder gleich ${min} sein.',
    max: 'Die Eingabe muss kleiner oder gleich ${max} sein.',
    lessThan: 'Die Eingabe muss kleiner sein als ${less}.',
    moreThan: 'Die Eingabe muss größer sein als ${more}.',
    notEqual: 'Die Eingabe darf nicht gleich sein mit "${notEqual}".',
    positive: 'Die Eingabe muss eine positive Zahl sein.',
    negative: 'Die Eingabe muss eine negative Zahl sein.',
    integer: 'Die Eingabe muss eine ganze Zahl sein.',
  },
  date: {
    min: 'Die Eingabe muss später sein als ${min}.',
    max: 'Die Eingabe muss früher sein als ${max}.',
  },
  object: {
    noUnknown: 'Die Eingabe darf keine Schlüssel verwenden, die nicht im "Objekt-Shape" definiert wurden.',
  },
  array: {
    min: 'Die Eingabe muss mindesten ${min} Einträge haben.',
    max: 'Die Eingabe darf höchstens ${max} Einträge haben.',
  },
});
