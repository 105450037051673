import { ThemeProvider } from '@mui/material';
import { theme } from '@portals/core/src/themes/immobilien/main';
// eslint-disable-next-line import/no-extraneous-dependencies
import { act, render } from '@testing-library/react';
import { use } from 'i18next';
import React from 'react';
import { initReactI18next } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import renderer from 'react-test-renderer';

import { I18NEXT_OPTIONS } from './testUtils';

export const basicRenderTest = (componentName: string, page: JSX.Element): void => {
  describe(componentName, function () {
    beforeEach(() => {
      use(initReactI18next).init(I18NEXT_OPTIONS);
    });

    rendersCorrectly(page);
  });
};

export const rendersCorrectly = (page: JSX.Element): void => {
  it(`renders correctly`, async () => {
    const root = renderer.create(page);

    expect(root.toJSON()).toMatchSnapshot();

    renderer.act(() => {
      root.unmount();
    });
  });
};

export const shouldMatchThemedVirtualDOMSnapshot = (component: JSX.Element): void => {
  it(`renders without crashing and matches snapshot`, async () => {
    const tree = renderer.create(<ThemeProvider theme={theme}>{component}</ThemeProvider>);

    expect(tree.toJSON()).toMatchSnapshot();

    renderer.act(() => {
      tree.unmount();
    });
  });
};

export const shouldMatchThemedDOMSnapshot = (component: JSX.Element, customDescription?: string): void => {
  const description = customDescription ?? 'renders without crashing and matches snapshot';
  it(description, async () => {
    const tree = render(<ThemeProvider theme={theme}>{component}</ThemeProvider>);

    expect(tree).toMatchSnapshot();

    act(() => {
      tree.unmount();
    });
  });
};
