import { AxiosResponse } from 'axios';

import {
  AutoCompleteOption,
  EstateContactMessageProps,
  EstateCountResponse,
  EstateDetail,
  EstateListResponse,
  EstateSearchProps,
  FinanceData,
  UserEventData,
} from './ApiClientTypes';

export interface ApiClient {
  getEstates(query: any): Promise<EstateListResponse>;
  getEstateCount(searchParams: EstateSearchProps): Promise<EstateCountResponse>;
  getEstateDetail(params: string): Promise<EstateDetail>;
  getAutoCompleteOptions(term: string): Promise<Array<AutoCompleteOption>>;
  getLoans(query: FinanceData): Promise<AxiosResponse>;
  clientSendEstateContactMessage(contactMessage: EstateContactMessageProps): Promise<boolean>;
  sendUserEvent(userEvent: UserEventData): Promise<void>;
}

export class ApiClientProvider {
  private static apiClient: ApiClient;
  public static setApiClient(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }
  public static getApiClient(): ApiClient {
    return this.apiClient;
  }
}
