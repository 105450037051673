import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const ObjectDescriptionData: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M8.25 10.5H10.125C10.539 10.5 10.875 10.164 10.875 9.75C10.875 9.336 10.539 9 10.125 9H8.25C7.836 9 7.5 9.336 7.5 9.75C7.5 10.164 7.836 10.5 8.25 10.5Z" />
    <path d="M8.25 12.75C7.836 12.75 7.5 13.086 7.5 13.5C7.5 13.914 7.836 14.25 8.25 14.25H11.7938L13.2938 12.75H8.25Z" />
    <path d="M10.8855 16.5H8.25C7.836 16.5 7.5 16.836 7.5 17.25C7.5 17.664 7.836 18 8.25 18H10.5443C10.5487 17.9047 10.56 17.8162 10.5772 17.7292L10.575 17.7413L10.8855 16.5Z" />
    <path d="M18.75 21H5.25V3H13.5V6.75C13.5 7.57875 14.1713 8.25 15 8.25H17.8118C18.3773 7.719 19.14 7.39275 19.9785 7.3905H19.9792C20.0648 7.3905 20.1488 7.41 20.2335 7.41675C20.2192 7.2405 20.1443 7.0845 20.0303 6.96675L14.7803 1.71675C14.6445 1.5825 14.4585 1.5 14.253 1.5C14.2522 1.5 14.2507 1.5 14.25 1.5H5.25C4.42125 1.5 3.75 2.17125 3.75 3V21C3.75 21.8288 4.42125 22.5 5.25 22.5H18.75C19.5787 22.5 20.25 21.8288 20.25 21V14.8905L18.75 16.3905V21ZM15 4.0605L17.6895 6.75H15V4.0605Z" />
    <path d="M12.2993 15.4874L11.667 18.0171C11.6528 18.0719 11.6445 18.1341 11.6445 18.1986C11.6445 18.6126 11.9798 18.9479 12.3938 18.9486C12.3945 18.9486 12.396 18.9486 12.3968 18.9486C12.4605 18.9486 12.522 18.9404 12.5805 18.9246L12.5753 18.9261L15.105 18.2931C15.2423 18.2586 15.36 18.1896 15.453 18.0966L21.8708 11.6789C22.029 11.5206 22.1303 11.3046 22.14 11.0654V11.0639C22.149 10.9866 22.1543 10.8974 22.1543 10.8066C22.1543 9.8804 21.6173 9.08015 20.8373 8.69915L20.8238 8.69315C20.5763 8.58065 20.286 8.51465 19.9815 8.51465C19.404 8.51465 18.882 8.75015 18.5048 9.1304L12.4943 15.1386C12.402 15.2324 12.3338 15.3501 12.3 15.4821L12.2993 15.4874ZM13.704 16.0529L19.5653 10.1916C19.6718 10.0844 19.8195 10.0176 19.983 10.0176C20.0693 10.0176 20.151 10.0364 20.2253 10.0694L20.2215 10.0679C20.472 10.2059 20.6385 10.4684 20.6385 10.7691C20.6385 10.7766 20.6385 10.7841 20.6385 10.7916V10.7901L14.5403 16.8876L13.4243 17.1666L13.704 16.0529Z" />
  </SvgIcon>
);
