import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const Pins: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M16.4987 2.03271C14.4527 2.09196 12.667 3.15322 11.608 4.74022L11.5938 4.76271C12.0887 4.95096 12.5117 5.16021 12.91 5.40472L12.8762 5.38522C13.7005 4.24446 15.007 3.49672 16.4905 3.44272H16.4987C19.0075 3.54847 21.0017 5.60872 21.0017 8.13397C21.0017 8.19097 21.001 8.24722 20.9987 8.30347V8.29522C20.9987 11.5127 17.6762 14.4752 16.4987 15.4352L16.3712 15.3377C16.174 15.8597 15.9647 16.3 15.724 16.7192L15.7487 16.6727C15.8837 16.7777 15.9887 16.8602 16.0487 16.8977C16.162 16.9817 16.3037 17.032 16.4575 17.032C16.6112 17.032 16.7537 16.9817 16.8678 16.8962L16.8662 16.8977C17.4287 16.4927 22.3862 12.8177 22.3862 8.29522C22.3877 8.24722 22.3885 8.19097 22.3885 8.13397C22.3885 4.83772 19.7762 2.15196 16.51 2.03346H16.4995L16.4987 2.03271Z" />
    <path d="M16.4969 5.16748C16.4879 5.16748 16.4766 5.16748 16.4661 5.16748C15.5181 5.16748 14.6804 5.64148 14.1779 6.36598L14.1719 6.37498C14.5394 6.71398 14.8694 7.08148 15.1619 7.47898L15.1769 7.49998C15.3741 6.95773 15.8849 6.57748 16.4841 6.57748C16.4886 6.57748 16.4931 6.57748 16.4976 6.57748H16.4969C17.2634 6.57748 17.8844 7.19848 17.8844 7.96498C17.8844 8.73148 17.2634 9.35248 16.4969 9.35248C16.4646 9.35398 16.4264 9.35548 16.3881 9.35548C16.3499 9.35548 16.3116 9.35473 16.2741 9.35248H16.2794C16.4511 9.74998 16.6034 10.221 16.7121 10.7085L16.7219 10.7625C18.1641 10.6462 19.2906 9.44923 19.2944 7.98748C19.2944 7.98073 19.2944 7.97323 19.2944 7.96498C19.2944 6.41998 18.0419 5.16748 16.4969 5.16748Z" />
    <path d="M8.62425 22.4175C8.4705 22.416 8.32725 22.3688 8.2095 22.2885L8.2125 22.29C7.935 22.11 1.5 17.79 1.5 12.4575C1.5 8.52227 4.68975 5.33252 8.625 5.33252C12.5603 5.33252 15.75 8.52227 15.75 12.4575C15.75 17.76 9.315 22.11 9.0375 22.29C8.922 22.3688 8.77875 22.416 8.625 22.4175H8.62425ZM8.62425 6.83252C5.51925 6.83702 3.00375 9.35252 2.99925 12.4568V12.4575C2.99925 16.2525 7.25925 19.725 8.62425 20.745C9.98925 19.725 14.2493 16.245 14.2493 12.495C14.2493 12.486 14.2493 12.4755 14.2493 12.465C14.2493 9.35702 11.7315 6.83702 8.62425 6.83252H8.6235H8.62425Z" />
    <path d="M8.6225 15.33C7.03625 15.33 5.75 14.0437 5.75 12.4575C5.75 10.8712 7.03625 9.58496 8.6225 9.58496C10.2087 9.58496 11.495 10.8712 11.495 12.4575C11.495 14.0437 10.2087 15.33 8.6225 15.33ZM8.6225 11.085C7.86425 11.085 7.25 11.6992 7.25 12.4575C7.25 13.2157 7.86425 13.83 8.6225 13.83C9.38075 13.83 9.995 13.2157 9.995 12.4575C9.995 11.6992 9.38075 11.085 8.6225 11.085Z" />
  </SvgIcon>
);
