import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const DottedList: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M3.75 6.75C3.75 7.371 3.246 7.875 2.625 7.875C2.004 7.875 1.5 7.371 1.5 6.75C1.5 6.129 2.004 5.625 2.625 5.625C3.246 5.625 3.75 6.129 3.75 6.75Z" />
    <path d="M3.75 12C3.75 12.621 3.246 13.125 2.625 13.125C2.004 13.125 1.5 12.621 1.5 12C1.5 11.379 2.004 10.875 2.625 10.875C3.246 10.875 3.75 11.379 3.75 12Z" />
    <path d="M3.75 17.25C3.75 17.871 3.246 18.375 2.625 18.375C2.004 18.375 1.5 17.871 1.5 17.25C1.5 16.629 2.004 16.125 2.625 16.125C3.246 16.125 3.75 16.629 3.75 17.25Z" />
    <path d="M21.75 7.5H6C5.55 7.5 5.25 7.2 5.25 6.75C5.25 6.3 5.55 6 6 6H21.75C22.2 6 22.5 6.3 22.5 6.75C22.5 7.2 22.2 7.5 21.75 7.5Z" />
    <path d="M21.75 12.75H6C5.55 12.75 5.25 12.45 5.25 12C5.25 11.55 5.55 11.25 6 11.25H21.75C22.2 11.25 22.5 11.55 22.5 12C22.5 12.45 22.2 12.75 21.75 12.75Z" />
    <path d="M21.75 18H6C5.55 18 5.25 17.7 5.25 17.25C5.25 16.8 5.55 16.5 6 16.5H21.75C22.2 16.5 22.5 16.8 22.5 17.25C22.5 17.7 22.2 18 21.75 18Z" />
  </SvgIcon>
);
