import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const LightBulb: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M15.6015 20.2501H8.4015C8.3595 20.2583 8.3115 20.2628 8.26275 20.2628C7.84125 20.2628 7.5 19.9216 7.5 19.5001C7.5 19.0786 7.84125 18.7373 8.26275 18.7373C8.3115 18.7373 8.36025 18.7418 8.40675 18.7508L8.40225 18.7501H15.6022C15.6442 18.7418 15.6922 18.7373 15.741 18.7373C16.1625 18.7373 16.5037 19.0786 16.5037 19.5001C16.5037 19.9216 16.1625 20.2628 15.741 20.2628C15.6922 20.2628 15.6435 20.2583 15.597 20.2493L15.6015 20.2501Z" />
    <path d="M15 22.5H9C8.586 22.5 8.25 22.164 8.25 21.75C8.25 21.336 8.586 21 9 21H15C15.414 21 15.75 21.336 15.75 21.75C15.75 22.164 15.414 22.5 15 22.5Z" />
    <path d="M15.75 18H8.25C7.836 18 7.5 17.664 7.5 17.25V14.8627L7.32525 14.7083C5.6235 13.365 4.53225 11.313 4.5 9.00525V9C4.5 4.85775 7.85775 1.5 12 1.5C16.1423 1.5 19.5 4.85775 19.5 9C19.4677 11.313 18.3765 13.3643 16.6905 14.6955L16.6747 14.7075C16.6095 14.7668 16.5495 14.8185 16.5 14.8627V17.25C16.5 17.664 16.164 18 15.75 18ZM9 16.5H15V14.6138C15 14.19 15.285 13.9387 15.675 13.587C17.0722 12.5242 17.973 10.8698 18 9.0045V9C18 5.6865 15.3135 3 12 3C8.6865 3 6 5.6865 6 9C6.027 10.8698 6.92775 12.5242 8.31075 13.5765L8.325 13.587C8.718 13.9372 9 14.187 9 14.6115V16.5Z" />
  </SvgIcon>
);
